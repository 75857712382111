import { post, put, get, remove } from '../commonAPI';
import config from '../../config';

export const FetchPatientRowWithoutFilterMonolith = (payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients`;
  return post(url, payload);
};

export const FetchMeasuresDataMonolith = (patientId: string, taskId: string) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures/${taskId}`;
  return get(url);
};
export const FetchMeasuresMonolith = (patientId: string, isSearchPatientClick: boolean = false) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures?isSearch=${isSearchPatientClick}`;
  return get(url);
};

export const FetchPharmacyPrescriberMonolith = (patientId?: string) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}`;
  return get(url);
};
export const FetchMonolithNotes = (id: string) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${id}/notes`;
  return get(url);
};

export const FetchMonolithLogs = (id: string) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${id}/activities`;
  return get(url);
};

export const SaveNote = (patientId: string, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/notes`;
  return post(url, payload);
};

export const SaveEditedNote = (patientId: string, noteId: string, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/notes/${noteId}`;
  return post(url, payload);
};

export const FetchPatientMedication = (id: string, measureId: number) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${id}/measures/${measureId}`;
  return get(url);
};

export const PostMeasureData = (id: string, measureId: number, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${id}/measures/${measureId}/tasksv2`;
  return post(url, payload);
};

export const PostGapData = (measureGapId: number) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/addResearchStep/patientMeasureGapId/${measureGapId}`;
  return post(url, {});
};

export const FetchStepAnswer = (patientId: string, measureId: number, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures/${measureId}/tasksv3`;
  return post(url, payload);
};
export const FetchStepResponse = (patientId: string, measureId: number, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures/${measureId}/tasksv2`;
  return post(url, payload);
};
export const fetchPatientMeasure = (id: string) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${id}/measures`;
  return get(url);
};

export const editPatientDetailsMono = (id: string, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${id}`;
  return put(url, payload);
};

export const fetchPatientPharmacy = (payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/pharmacies/search`;
  return post(url, payload);
};

export const fetchLastWorkedPatientsMonolith = async () => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/lastWorkedOn`;
  const response = await get(url);
  const res = {
    result: response.searchedPatients.map((patient: any) => {
      return {
        ...patient,
        dob: patient.birthDate,
        phoneNumber: patient.mobilePhone,
      };
    }),
    metadata: {
      total: response.metadata.count,
    },
  };
  return res;
};

export const postPatientMeasureGap = async (patientId: number, measureId: number) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures/${measureId}/gaps`;
  return post(url);
};

export function getPatientCallQueue(patientId: string, patientMeasureId: number) {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures/${patientMeasureId}/refill-reminder`;
  return get(url);
}

export function addPatientToCallQueue(patientId: string, patientMeasureId: number) {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures/${patientMeasureId}/refill-reminder`;
  return post(url);
}

export function removePatientToCallQueue(
  patientId: string,
  patientMeasureId: number,
  refillReminderId: string,
) {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/measures/${patientMeasureId}/refill-reminder/${refillReminderId}`;
  return remove(url);
}

export function updatePatientToCallQueue(payload: any) {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/five9-events/update-refill-reminder`;
  return post(url, payload);
}

export function fetchPatientBasicInfo(patientId: number) {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/${patientId}/basic-info`;
  return get(url);
}
